.ah1 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  padding-top: 10vh;
  font-size: 3rem;
  color: #113f67;
}

.ap1,
.ap2,
.ap3 {
  font-family: "Roboto Slab", serif;
  color: #226597;
  width: 730px;
  align-self: center;
  margin: 0 10% 0;
}

.ap1 {
  padding-top: 5%;
}

.ap3 {
  padding-bottom: 7%;
}

.about-container {
  background-color: #d3e0ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
}

@media screen and (max-width: 960px) {
  .ap1,
  .ap2,
  .ap3 {
    width: 80%;
  }

  .about-container {
    margin-bottom: 2px;
  }
}
