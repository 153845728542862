.footer-container {
  position: relative;
  background-color: #113f67;
  padding-top: 50px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  font-family: "Montserrat", sans-serif;
  color: #f6f5f5;
}
.social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}

.social-icon {
  font-size: 1.5rem;
  color: #fff;
  display: flex;
  width: 50px;
  justify-content: space-between;
}

.fa-facebook-square,
.fa-twitter-square {
  color: #fff;
}

@media screen and (max-width: 960px) {
  .social-wrapper {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
}
