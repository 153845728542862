.remove-container {
  background-image: url("../../Img/remove-bg.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  width: 100%;
  display: flex;

  align-items: center;
  flex-direction: column;
}

.rip {
  font-family: "Roboto Slab", serif;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.rh1 {
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 50px;
  font-size: 3rem;
  color: #113f67;
  width: auto;
  text-align: center;
}

.rp {
  max-width: 650px;
  margin-left: 90px;
  line-height: 1.5;
}
.ri {
  width: 20vw;
}

@media screen and (max-width: 960px) {
  .remove-container {
    height: 150%;
  }
  .rip {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .rp {
    margin: 30px;
    padding: 5% 0 10%;
    line-height: 1.5;
  }

  .rh1 {
    margin-top: 5rem;
    text-align: center;
  }
  .ri {
    width: 70%;
  }
}
