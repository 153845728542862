.cards-section {
  background-color: #fff;
  padding-top: 0px;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 3s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, 20%, 0); */
    transform: translate3d(0, 200%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
}

.cards-wrapper {
  margin: 30px 0 45px 0;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #113f67;

  text-align: center;
  max-width: 340px;
}

.item-title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  align-items: center;
  padding: 5% 10% 2%;
  color: #f3f9fb;
  height: 100%;
}

.item-text {
  font-family: "Roboto Slab", serif;
  padding: 10% 8% 40%;
  line-height: 1.5;
  color: #f3f9fb;
  font-size: 1rem;
}

.item-button {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  /* background-color: #226597; */
  width: 50%;
  text-decoration: none;
  color: #f3f9fb;
  font-size: 1.2rem;
  padding: 10px;
  margin: 0 auto 20%;
  background: #226597;
  background-image: -webkit-linear-gradient(top, #226597, #1b7cb8);
  background-image: -moz-linear-gradient(top, #226597, #1b7cb8);
  background-image: -ms-linear-gradient(top, #226597, #1b7cb8);
  background-image: -o-linear-gradient(top, #226597, #1b7cb8);
  background-image: linear-gradient(to bottom, #226597, #1b7cb8);
}

.item-button:hover {
  color: #226597;
  /* background-color: #d3e0ea; */
  background: #f6f5f5;
  background-image: -webkit-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: -moz-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: -ms-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: -o-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: linear-gradient(to bottom, #f6f5f5, #d3e0ea);
}

@media screen and (min-width: 961px) {
  .cards-wrapper {
    display: flex;
  }

  .item {
    margin: 0 20px;
  }
}

@media screen and (max-width: 960px) {
  .item {
    margin-bottom: 2px;
    width: 100%;
    max-width: 100%;
  }

  .item-text {
    padding: 5% 10% 10%;
  }

  .cards-wrapper {
    margin: 0;
    text-align: center;
  }
}
