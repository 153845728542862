.img-container {
  height: 80vh;
  width: 100%;
}

.hi {
  position: fixed;
  z-index: -1;
  width: 100%;
}
@media screen and (max-width: 960px) {
  .img-container {
    height: 20vh;
  }
}
