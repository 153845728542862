.navbar {
  background-color: #113f67;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar-container {
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: center;
  align-items: center;
  height: 120px;

  width: auto;
}
.navbar-logo {
  font-family: "Roboto Slab", serif;
  color: #87c0cd;
  justify-self: start;
  margin-left: 50px;
  margin-right: 150px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100px;
}

.menu-list {
  display: grid;
  grid-template-columns: repeat(5, auto);

  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  font-family: "Montserrat", sans-serif;
  color: #f3f9fb;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 1.3rem;
}

.nav-links:hover {
  color: #87c0cd;
  transition: 0.5s ease-in-out;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1240px) {
  .menu-list-container {
    position: relative;
  }

  .navbar-logo {
    transform: translate(-10%, 0%);
    text-align: center;
    margin: auto;
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-list.active {
    background: lightsteelblue;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    width: 100%;
    padding-inline-start: 0px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #113f67;
    color: #87c0cd;
    transition: all 0.5s ease;
  }

  .menu-icon {
    display: block;
    position: absolute;
    color: #f3f9fb;
    top: 5px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
