.contact {
  padding-top: 20px;
  background-color: #113f67;
  padding-bottom: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contact-container {
  display: flex;
  height: 500px;
  position: relative;
}

.map-image {
  margin-right: 50px;
  text-align: center;
}
.contact-title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 3rem;
  color: #f3f9fb;
  padding-bottom: 50px;
}

.map-container {
  align-items: center;

  height: 100%;
}

.submit-button {
  font-family: "Montserrat", sans-serif;
  text-align: center;

  width: auto;

  text-decoration: none;

  font-size: 1.2rem;

  color: #ffffff;

  /* background: #226597; */
  padding: 15px 40px 15px 40px;
  border: solid #1f628d 2px;
  text-decoration: none;
  background: #226597;
  background-image: -webkit-linear-gradient(top, #226597, #1b7cb8);
  background-image: -moz-linear-gradient(top, #226597, #1b7cb8);
  background-image: -ms-linear-gradient(top, #226597, #1b7cb8);
  background-image: -o-linear-gradient(top, #226597, #1b7cb8);
  background-image: linear-gradient(to bottom, #226597, #1b7cb8);
}

.submit-button:hover {
  color: #226597;
  /* background-color: #d3e0ea; */
  background: #f6f5f5;
  background-image: -webkit-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: -moz-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: -ms-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: -o-linear-gradient(top, #f6f5f5, #d3e0ea);
  background-image: linear-gradient(to bottom, #f6f5f5, #d3e0ea);
  text-decoration: none;
}

.address {
  font-family: "Roboto Slab", serif;
  text-align: center;
  font-size: 1.2rem;
  color: #87c0cd;
  padding-top: 33%;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::placeholder {
  font-family: "Montserrat", sans-serif;
  color: #226597;
}

.input-class {
  margin-bottom: 20px;
  width: 300px;
  height: 30px;
}
.input-class.message {
  height: 80px;
  width: 300px;
  resize: none;
}

@media screen and (max-width: 960px) {
  .contact-container {
    flex-direction: column;
    text-align: center;
    height: auto;
    width: 90%;
  }

  .map-image {
    margin: 0;
    text-align: center;
  }

  .map-image1 {
    margin: auto;
    max-width: 100%;
  }
  .address {
    padding-top: 10%;
  }
}
